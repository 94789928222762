import styled from 'styled-components'
import GridContainer from '../../common/containers/gridContainer'
import mixins from '../../../utils/mixins'
import { COLORS } from '../../../constants/stylesConstants'

const LandingInnerContainer = styled(GridContainer)`
  ${mixins
    .chain()
    .float()
    .swatched()}
  height: 100dvh;
  box-sizing: border-box;

  position: fixed;
  top: 0;

  color: ${COLORS.BLACK};
  svg path {
    fill: ${COLORS.BLACK};
  }

  pointer-events: none;
`

export default LandingInnerContainer
import { useContext } from 'react'
import styled from 'styled-components'
import { FONT_SIZES, SIZES } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import Pages from '../../data/pages'
import mixins from '../../utils/mixins'
import { extract } from '../../utils/styleUtils'
import FullContainer from '../common/containers/fullContainer'
import MenuItem from '../common/headerItem'

const MenuMobile = ({ onClose, onContactToggle }) => {
  const { swatch } = useContext(GlobalContext)
  const onContactOpen = () => {
    onContactToggle(true)
    onClose()
  }

  return (
    <Container $color={swatch.MENU_TEXT} $background={swatch.MENU}>
      <MenuItem onClick={onClose} path={Pages.Projects} />
      <MenuItem onClick={onClose} path={Pages.Studio} />
      <button onClick={onContactOpen}>Contact</button>
    </Container>
  )
}

const Container = styled(FullContainer)`
  ${mixins.highZIndex(2)}
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${extract('$background')};
  box-sizing: border-box;
  padding-top: ${SIZES.MENU_PADDING_MOBILE.css};

  button, a, a:visited {
    color: ${extract('$color')};
    font-size: ${FONT_SIZES.MENU_MOBILE.css};
    display: block;
    width: fit-content;
    line-height: ${FONT_SIZES.MENU_MOBILE_LEADING.css};
    padding: 0;
  }
`

export default MenuMobile
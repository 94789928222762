import { forwardRef } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants/stylesConstants'
import useIsMobile from '../../../hooks/useIsMobile'
import mixins from '../../../utils/mixins'
import { extract } from '../../../utils/styleUtils'


const FullContainer = forwardRef(function FullContainer(props, ref) {
  const isMobile = useIsMobile()
  return <StyledContainer
    {...props}
    ref={ref}
    $padding={isMobile ? SIZES.MARGIN_MOBILE.css : SIZES.MARGIN.css} />
})

const StyledContainer = styled.div`
  ${mixins
    .chain()
    .float()
    .swatched()}
  width: 100dvw;
  height: 100dvh;
  box-sizing: border-box;
  padding: ${extract('$padding')};
`

export default FullContainer
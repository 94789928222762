// eslint-disable-next-line
import { useLenis } from 'lenis/react'
import { useContext, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { SIZES } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import useIsMobile from '../../hooks/useIsMobile'
import GridContainer from '../common/containers/gridContainer'
import ProjectItem from '../common/projectItem'
import mixins from '../../utils/mixins'
import { styleIf } from '../../utils/styleUtils'


const Projects = () => {
  const { data } = useContext(GlobalContext)
  const { projects } = data ?? {}

  const isMobile = useIsMobile()
  const [heights, setHeights] = useState([])
  const [maxDetailHeight, setMaxDetailHeight] = useState(undefined)
  const { className, onRendered } = useOutletContext()
  const [imgRendered, setImgRendered] = useState(Array(isMobile ? 2 : 3).fill(true))
  const [allRendered, setAllRendered] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    if (projects)
      setHeights(Array(projects.length).fill(undefined))
  }, [projects])


  useLenis(({ velocity }) => setIsScrolling(velocity >= 1))
  useEffect(() => { if (allRendered) onRendered() }, [allRendered])

  const handleCitationRender = (i, height) =>
    setHeights(prev => {
      const newHeights = [...prev]
      newHeights[i] = height
      return newHeights
    })

  const handleImgRender = i =>
    setImgRendered(prev => {
      const newImgRendered = [...prev]
      newImgRendered[i] = true
      return newImgRendered
    })

  useEffect(() => {
    if (maxDetailHeight !== undefined && imgRendered.every(r => r))
      setAllRendered(true)
  }, [maxDetailHeight, imgRendered])


  useEffect(() => {
    if (heights?.every(h => h !== undefined))
      setMaxDetailHeight(Math.max(...heights))
  }, [heights])

  return (
    <Container className={className} $isMobile={isMobile}>
      {projects?.map((project, i) =>
        <ProjectItem
          key={i}
          img={projects?.[i].projectImage}
          project={project}
          minMargin={maxDetailHeight}
          rendered={allRendered}
          isScrolling={isScrolling}
          onImgRender={() => handleImgRender(i)}
          onCaptionRender={height => handleCitationRender(i, height)} />)}
    </Container>
  )
}

const footerGuard = 'min-height: 100vh;'
const Container = styled(GridContainer)`
  ${mixins.float()}
  ${footerGuard}
  display: ${styleIf('$isMobile', 'block')};
  margin-top: ${styleIf('$isMobile', SIZES.PROJECT_TOP_MOBILE.css, SIZES.PROJECT_TOP.css)};
  opacity: 0;
`

export default Projects
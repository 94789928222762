import { PortableText } from '@portabletext/react'
import { useContext } from 'react'
import styled from 'styled-components'
import { FONT_SIZES, SIZES } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import parserServices from '../../../services/parserServices'
import mixins from '../../../utils/mixins'
import { extract } from '../../../utils/styleUtils'
import Anchor from '../../common/anchor'
import FullContainer from '../../common/containers/fullContainer'
import withLinks from '../../sanity/withLinks'

const ContactMobile = () => {
  const { data, swatch } = useContext(GlobalContext)
  const {
    contactDescription,
    email,
    instagramHandle,
    phone
  } = data?.contact ?? {}

  const { url, handle } = parserServices.parseInstagramHandle(instagramHandle)


  return (
    <Container
      $color={swatch.CONTACT_TEXT}
      $background={swatch.CONTACT}>
      <div>
        <Survey>
          <PortableText value={contactDescription} components={withLinks} />
        </Survey>
        <div>
          <Anchor to={`mailto:${email}`}>{email}</Anchor>
          <p>{phone}</p>
        </div>
        <div><Anchor to={url}>{handle}</Anchor></div>
      </div>
    </Container>

  )
}

const Container = styled(FullContainer)`
  ${mixins.highZIndex(3)}
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: flex-end;

  padding: ${SIZES.MARGIN_MOBILE.css};
  background-color: ${extract('$background')};

  &, a {
    color: ${extract('$color')};
  }

  &, p {
    font-size: ${FONT_SIZES.MEDIUM_MOBILE.css};
    line-height: ${FONT_SIZES.MEDIUM_LEADING_MOBILE.css};
  }

  > div > div {
    ${mixins.innerSpacing(FONT_SIZES.MEDIUM_LEADING_MOBILE.css)};
  }
`

const Survey = styled.div`
   a {
    text-decoration-line: underline;
  }
`



export default ContactMobile
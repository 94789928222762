import Size from '../utils/helpers/size'
import { getSize } from '../utils/sizeUtils'

const SMALL_LEADING_PERCENTAGE = 1.2
const REGULAR_LEADING_PERCENTAGE = 1.15
const MEDIUM_LEADING_PERCENTAGE = 1.1

const SMALL = getSize({ xl: 22, xxl: 31 })
const REGULAR = getSize({ xl: 30, xxl: 36 })
const MEDIUM = getSize({ xl: 60, xxl: 76 })


const SMALL_MOBILE = getSize({ xs: 17.5, s: 25 })
const REGULAR_MOBILE = getSize({ xs: 20, s: 32 })
const MEDIUM_MOBILE = getSize({ xs: 26, s: 48 })

export const FONT_SIZES = {
  SMALL,
  SMALL_LEADING: SMALL.mult(SMALL_LEADING_PERCENTAGE),
  REGULAR,
  REGULAR_LEADING: REGULAR.mult(REGULAR_LEADING_PERCENTAGE),
  LI_LEADING: getSize({ xl: 49.5, xxl: 56 }),
  MEDIUM,
  MEDIUM_LEADING: MEDIUM.mult(MEDIUM_LEADING_PERCENTAGE),

  SMALL_MOBILE,
  SMALL_LEADING_MOBILE: SMALL_MOBILE.mult(SMALL_LEADING_PERCENTAGE),
  REGULAR_MOBILE,
  REGULAR_LEADING_MOBILE: REGULAR_MOBILE.mult(REGULAR_LEADING_PERCENTAGE),
  MEDIUM_MOBILE,
  MEDIUM_LEADING_MOBILE: MEDIUM_MOBILE.mult(MEDIUM_LEADING_PERCENTAGE),

  BUTTON_MOBILE: getSize({ xs: 22, s: 34 }),
  MENU_MOBILE: getSize({ xs: 40, s: 60 }),
  MENU_MOBILE_LEADING: getSize({ xs: 50, s: 75 })
}

const WHITE = '#F1F1F1'
const BLACK = '#000000'
const GREEN = '#222F2D'
const GOLD = '#BA9B62'
const LIGHT_GOLD = '#B89049'

export const COLORS = {
  WHITE, BLACK, GREEN, GOLD, LIGHT_GOLD
}

export const SWATCHES = {
  BUTTON: [GOLD, GREEN, WHITE],
  TEXT: [BLACK, BLACK, WHITE],
  BACKGROUND: [WHITE, GOLD, GREEN],
  TINT: [LIGHT_GOLD, GOLD, GREEN],
  MENU: [GREEN, WHITE, GOLD,],
  MENU_TEXT: [WHITE, BLACK, BLACK],
  CONTACT: [GOLD, GREEN, WHITE],
  CONTACT_TEXT: [BLACK, WHITE, BLACK],
}

const MARGIN = getSize({ xl: 35, xxl: 45 })
const MARGIN_MOBILE = getSize({ xs: 20, s: 27.5 })

export const SIZES = {
  COL_COUNT: 12,
  GAP: getSize({ xl: 20, xxl: 25 }),
  MARGIN,
  MAIN_H: Size.subFromFullHeight(MARGIN.mult(2)),
  MAIN_W: Size.subFromFullWidth(MARGIN.mult(2)),
  HERO_COPY_W: getSize({ xl: 555, xxl: 675 }),

  LANDING_CAROUSEL_GAP: getSize({ xl: 225, xxl: 350 }),
  LANDING_WORKMARK_W: getSize({ xl: 800, xxl: 1250 }),
  LANDING_ENTER_GAP: getSize({ xl: 350, xxl: 600 }),

  STUDIO_BIO_BOT: getSize({ xl: 150, xxl: 225 }),
  LIST_PADDING: getSize({ xl: 50, xxl: 65 }),

  STUDIO_CAROUSEL_MAX_HEIGHT_HORIZONTAL: getSize({ xl: 550, xxl: 900 }),
  STUDIO_CAROUSEL_MAX_HEIGHT_VERTICAL: getSize({ xl: 650, xxl: 950 }),
  STUDIO_CAROUSEL_TOP: MARGIN.mult(2).add(getSize({ xl: 30, xxl: 36 })),

  PROJECT_TOP: getSize({ xl: 135, xxl: 235 }),
  PROJECT_MIN_MARGIN: getSize({ xl: 135, xxl: 235 }),
  PROJECT_SHIFT: getSize({ xl: -32, xxl: -45 }),
  PROJECT_PARAGRAPH_PADDING: getSize({ xl: 18, xxl: 22 }),

  LANDING_SCROLL_VELOCITY: getSize({ l: 1.2, xxl: 2.8 }),

  LANDING_FIRST_IMG_WIDTH: getSize({ l: 716, xxl: 1100 }),

  WORDMARK_SVG_FACTOR: 0.785,
  MARGIN_MOBILE,
  PROJECT_TOP_MOBILE: getSize({ xs: 65, s: 90 }),
  MAIN_W_MOBILE: Size.subFromFullWidth(MARGIN_MOBILE.mult(2)),
  MAIN_H_MOBILE: Size.subFromFullHeight(MARGIN_MOBILE.mult(2)),
  MENU_PADDING_MOBILE: getSize({ xs: 140, s: 200 }),

  PROJECT_PARAGRAPH_PADDING_TOP_MOBILE: getSize({ xs: 18, s: 24 }),
  PROJECT_PARAGRAPH_PADDING_BOT_MOBILE: getSize({ xs: 30, s: 45 }),

  PROJECT_MARGIN_MOBILE: getSize({ xs: 60, s: 72 }),
  PROJECT_SHIFT_MOBILE: getSize({ xs: -30, s: -35 }),
  FOOTER_MARGIN_TOP_MOBILE: getSize({ xs: 300, s: 480 }),
  FOOTER_MARGIN_BOTTOM_MOBILE: getSize({ xs: 55, s: 70 }),
  STUDIO_HEADER_MARGIN_TOP_MOBILE: getSize({ xs: 110, s: 180 }),
  STUDIO_HEADER_PADDING_BOTTOM_MOBILE: getSize({ xs: 10, s: 15 }),
  LIST_PADDING_MOBILE: getSize({ xs: 10, s: 15 }),
  GAP_MOBILE: getSize({ xs: 15, s: 20 }),
  STUDIO_TOP_MOBILE: getSize({ xs: 275, s: 450 }),

  STUDIO_CAROUSEL_MAX_HEIGHT_MOBILE_HORIZONTAL: getSize({ xs: 240, s: 520 }),
  STUDIO_CAROUSEL_MAX_HEIGHT_MOBILE_VERTICAL: getSize({ xs: 400, s: 720 }),
  LANDING_CAROUSEL_GAP_MOBILE: getSize({ xs: 250, s: 320 }),
  LANDING_CAROUSEL_FIRST_MARGIN_MOBILE: getSize({ xs: 20, s: 40 }),
  LANDING_CAROUSEL_FIRST_EXTRA_MARGIN_MOBILE: getSize({ xs: 10, s: 20 }),
  LANDING_CAROUSEL_FIRST_MARGIN_COMPENSATION_MOBILE: getSize({ xs: 10, s: 15 }),
  LANDING_ENTER_GAP_MOBILE: getSize({ xs: 325, s: 350 }),
  LANDING_SCROLL_VELOCITY_MOBILE: getSize({ xs: 2, s: 3 }),
}

export const TIME = {
  ACCORDION: 400,
  STUDIO: 650,
  LANDING_DURATION: [1, 0.6],
  LANDING_FADE_OUT: 0.1,
  LANDING_FLAT_DELAY: 0.1,
  PROJECT: 250,
  PAGE_DURATION: [0.4, 0.3],
  LANDING_SCROLL_DELAY: 1000,
  COLOR_CHANGE: 250,
  CONTACT: 325,
  LANDING_SCROLL_FADE_MOBILE: 150,
  LANDING_SHRINK_LOWER: 550,
  LANDING_SHRINK_UPPER: 900,
  MOBILE_VH_CHANGE: 250,
}


export const LANDING_ANIMATIONS = [
  { cls: 'landing-word-mark', delay: 0 },
  { cls: 'landing-hero-copy', delay: -0.5 },
  { cls: 'landing-carousel', delay: -2 },
]


export const PAGE_ANIMATIONS = [
  { cls: 'page-header', delay: 0.1 },
  { cls: 'page-body', delay: 0 }
]

export const WORD_MARK_ASPECT_RATIO = 6.833

export const EASINGS = {
  IN_QUAD: 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  IN_CUBIC: 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  OUT_QUAD: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  OUT_CUBIC: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  OUT_QUART: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  IN_OUT_QUAD: 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  IN_OUT_CUBIC: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  CUSTOM: 'cubic-bezier(0.770, 0.000, 0.200, 1.000)'
}
import { PortableText } from '@portabletext/react'
import { useClickAway } from '@uidotdev/usehooks'
import { useContext } from 'react'
import styled from 'styled-components'
import { EASINGS, FONT_SIZES, SIZES, TIME } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import parserServices from '../../../services/parserServices'
import mixins from '../../../utils/mixins'
import { extract, styleIf } from '../../../utils/styleUtils'
import Anchor from '../../common/anchor'
import GridContainer from '../../common/containers/gridContainer'
import GridItem from '../../common/gridItem'
import withLinks from '../../sanity/withLinks'

const Contact = ({ isOpened, onClose }) => {
  const { data, swatch } = useContext(GlobalContext)
  const {
    contactDescription,
    email,
    instagramHandle,
    phone
  } = data?.contact ?? {}

  const { url, handle } = parserServices.parseInstagramHandle(instagramHandle)

  const ref = useClickAway(onClose)
  return (
    <Container
      ref={ref}
      $color={swatch.MENU_TEXT}
      $background={swatch.MENU}
      $isOpened={isOpened}>
      <Survey as='div' $end='span 5'>
        <PortableText value={contactDescription} components={withLinks} />
      </Survey>
      <GridItem $start='7' $end='span 3'>
        <Anchor to={`mailto:${email}`}>{email}</Anchor>
        <p>{phone}</p>
      </GridItem>
      <GridItem as='p'>
        <Anchor to={url}>{handle}</Anchor>
      </GridItem>
      <CloseButton $color={swatch.MENU_TEXT} $start='12'>
        <button onClick={onClose}>[close]</button>
      </CloseButton>
    </Container>

  )
}

const Container = styled(GridContainer)`
  ${mixins
    .chain()
    .highZIndex(3)
    .swatched()}
  position: fixed;
  top: 0;


  transform: translate(0, ${styleIf('$isOpened', 0, '-110%')});
  transition: transform ${EASINGS.IN_OUT_QUAD} ${TIME.CONTACT}ms;
  left: 0;
  padding: ${SIZES.MARGIN.css};
  background-color: ${extract('$background')};

  &, a {
    color: ${extract('$color')};
  }

  font-size: ${FONT_SIZES.REGULAR.css};
  box-sizing: border-box;
`

const Survey = styled(GridItem)`
   a {
    text-decoration-line: underline;
  }
`

const CloseButton = styled(GridItem)`
  ${mixins.flex('initial', 'flex-end')}
  font-size: ${FONT_SIZES.SMALL.css};
  button {
    color: ${extract('$color')};
  }
`

export default Contact
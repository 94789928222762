import imageUrlBuilder from '@sanity/image-url'
import he from 'he'
import parse, { domToReact } from 'html-react-parser'
import StudioCarousel from '../components/carousels/studioCarousel'
import apiServices from './apiServices'

const parseInstagramHandle = handle => {
  if (!handle) return {}
  const atRegExp = /^@/
  const url = `https://www.instagram.com/${handle.replace(atRegExp, '')}`
  handle = handle.match(atRegExp) ? handle : `@${handle}`
  return {
    url,
    handle
  }
}

const replaceNewLine = text => `<p>${he.decode(text).replaceAll(/\n+/g, '</p><p>')}</p>`
const parseText = text => {
  if (!text) return
  return parse(replaceNewLine(text))
}

const parseStudioBio = (bio, isMobile) => {
  if (!bio) return
  return parse(replaceNewLine(bio), {
    replace: domNode => {
      if (!domNode.parent && !domNode.prev)
        return <p>{!isMobile && 'TK Creative '}<StudioCarousel /> is {domToReact(domNode.children)}</p>
    }
  })
}

const parseImgData = img => {
  const refs = img.asset._ref.split('-')
  return {
    id: refs[1],
    dimensions: refs[2].split('x')
  }
}

const builder = imageUrlBuilder(apiServices.client)
const getImg = source => builder.image(source)

const parserServices = {
  parseInstagramHandle,
  parseText,
  parseStudioBio,
  parseImgData,
  getImg
}

export default parserServices
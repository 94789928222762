import { useGSAP } from '@gsap/react'
import { useWindowSize } from '@uidotdev/usehooks'
import gsap from 'gsap'
import KalmanFilter from 'kalmanjs'
import _ from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LANDING_ANIMATIONS, SIZES, TIME } from '../constants/stylesConstants'
import GlobalContext from '../context/context'
import Pages from '../data/pages'
import { addEventListener } from '../utils/reactUtils'
import useFade from './useFade'
import useIsMobile from './useIsMobile'

const useLanding = (updateCallback = _.noop, dependencies = [], disable) => {
  const { data } = useContext(GlobalContext)
  const { heroCopy } = data?.landing ?? {}
  const [loaded, setLoaded] = useState(false)
  const { width, height } = useWindowSize()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const animationFinishedRef = useRef(false)
  const velocity = isMobile ?
    SIZES.LANDING_SCROLL_VELOCITY_MOBILE.value :
    SIZES.LANDING_SCROLL_VELOCITY.value

  const lastScrollRef = useRef(document.documentElement.scrollTop)
  const directionRef = useRef(1)
  const startTimeRef = useRef()
  const resizedRef = useRef(false)
  const handleLoaded = () => setLoaded(true)

  const containerRef = useFade({
    data: LANDING_ANIMATIONS,
    duration: TIME.LANDING_DURATION,
    flatDelay: TIME.LANDING_FLAT_DELAY,
    shouldAnimate: loaded,
    callback: () =>
      startScrollTimeOutRef.current = setTimeout(() =>
        animationFinishedRef.current = true, TIME.LANDING_SCROLL_DELAY),
    cleanup: () => clearTimeout(startScrollTimeOutRef.current)
  })

  const startScrollTimeOutRef = useRef()
  const lastTouchedTimeRef = useRef()
  const kalmanRef = useRef()

  const { contextSafe } = useGSAP({ scope: containerRef })
  const handleClick = contextSafe(() => {
    gsap.to(LANDING_ANIMATIONS.map(animation => `.${animation.cls}`).join(', '), {
      opacity: 0,
      duration: TIME.LANDING_FADE_OUT,
      onComplete: () => navigate(Pages.Projects)
    })
  })

  useEffect(() => {
    const update = time => {
      if (!animationFinishedRef.current || disable) return

      if (!startTimeRef.current && !isMobile) {
        document.documentElement.scrollBy({ top: 1 })
        startTimeRef.current = time
      }

      const direction = directionRef.current
      const speed = Math.max(velocity, 1) * direction
      let scrollTop = document.documentElement.scrollTop

      if (!isMobile && (!isMobile || !lastTouchedTimeRef.current) && scrollTop > 0) {
        document.documentElement.scrollBy({ top: speed })
        scrollTop += speed
      }

      const delta = scrollTop - lastScrollRef.current
      lastScrollRef.current = scrollTop

      if (lastTouchedTimeRef.current) {
        const filteredDelta = kalmanRef.current.filter(delta)
        const elapsedTime = Date.now() - lastTouchedTimeRef.current
        const hasSlowedDown = direction === 1 ? filteredDelta <= speed : filteredDelta >= speed
        if (elapsedTime >= 50 && hasSlowedDown) {
          lastTouchedTimeRef.current = undefined
          kalmanRef.current = undefined
        }
      }

      if (resizedRef.current) {
        resizedRef.current = false
        if (scrollTop <= 0) directionRef.current = 1
      } else if (scrollTop > 0 && delta) {
        const atBottom =
          window.innerHeight + scrollTop >= document.body.scrollHeight
        const scrolledToBottom = direction === 1 && atBottom
        const shouldReverse = direction === 1 ? delta < -speed : delta > -speed
        if (shouldReverse && !scrolledToBottom) directionRef.current = direction * -1
      }

      updateCallback(time, delta)
    }

    gsap.ticker.add(update)

    return () => gsap.ticker.remove(update)
  }, [...dependencies, disable])

  useEffect(() => {
    history.scrollRestoration = 'manual'
    return () => history.scrollRestoration = 'auto'
  }, [])

  useEffect(() => { resizedRef.current = true }, [width, height])

  const onUserAction = () => {
    lastTouchedTimeRef.current ??= Date.now()
    kalmanRef.current ??= new KalmanFilter()
  }

  useEffect(() => addEventListener(document, 'scroll', () => {
    if (!isMobile) return
    const delta = Math.abs(document.documentElement.scrollTop - lastScrollRef.current)
    if (Math.round(delta) > Math.max(Math.round(velocity), 1))
      onUserAction()
  }), [])

  return {
    data,
    heroCopy,
    containerRef,
    handleClick,
    handleLoaded,
  }
}

export default useLanding
import _ from 'lodash'
import styled from 'styled-components'
import { COLORS, FONT_SIZES, LANDING_ANIMATIONS, SIZES } from '../../../constants/stylesConstants'
import Background from '../../common/background'
import FullContainer from '../../common/containers/fullContainer'
import GridItem from '../../common/gridItem'
import LandingCarousel from '../../carousels/landingCarousel'
import WordMark from '../../common/wordmark'
import useLanding from '../../../hooks/useLanding'
import LandingInnerContainer from './landingInnerContainer'



const LandingDesktop = () => {
  const {
    heroCopy,
    containerRef,
    handleClick,
    handleLoaded,
  } = useLanding()
  return (
    <Container ref={containerRef} onClick={handleClick}>
      <LandingCarousel
        className={LANDING_ANIMATIONS[2].cls}
        onLoad={handleLoaded} />
      <InnerContainer>
        <HeroCopy as='p' $end='span 5' className={LANDING_ANIMATIONS[1].cls}>
          {heroCopy}
        </HeroCopy>
        <WordMarkContainer $start='1' $end='6' className={LANDING_ANIMATIONS[0].cls}>
          <WordMark />
        </WordMarkContainer>
      </InnerContainer>
      <Background color={COLORS.WHITE} />
    </Container>
  )
}

const Container = styled(FullContainer)`
  cursor: pointer;
`

const InnerContainer = styled(LandingInnerContainer)`
  width: ${SIZES.MAIN_W.css};
  padding: ${SIZES.MARGIN.css} 0;
`

const HeroCopy = styled(GridItem)`
  width: ${SIZES.HERO_COPY_W.css};
  opacity: 0;
  font-size: ${FONT_SIZES.REGULAR.css};
  line-height: ${FONT_SIZES.REGULAR_LEADING.css};
`

const WordMarkContainer = styled(GridItem)`
  position: absolute;
  bottom: ${SIZES.MARGIN.sub(SIZES.LANDING_WORKMARK_W.mult(0.005)).css};
  width: ${SIZES.LANDING_WORKMARK_W.css};
  opacity: 0;
  display: flex;
  svg {
    height: auto;
  }
`

export default LandingDesktop
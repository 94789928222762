
import { PortableText } from '@portabletext/react'
import { Fragment, useContext, useEffect, useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { FONT_SIZES, SIZES } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import parserServices from '../../services/parserServices'
import mixins from '../../utils/mixins'
import { extract } from '../../utils/styleUtils'
import Accordion from '../common/accordion/accordion'
import GridContainer from '../common/containers/gridContainer'
import GridItem from '../common/gridItem'
import studioList from '../sanity/studioList'
import useIsMobile from '../../hooks/useIsMobile'

const Studio = () => {
  const { data, swatch } = useContext(GlobalContext)
  const { className, onRendered } = useOutletContext()
  const isMobile = useIsMobile()

  useEffect(() => onRendered(), [])

  const {
    bio,
    services,
    process
  } = data?.studio ?? {}

  const parsedBio = useMemo(() => parserServices.parseStudioBio(bio, isMobile), [bio])
  const BioContainer = isMobile ? BioContainerMobile : BioContainerDesktop
  const Bio = isMobile ? BioMobile : BioDesktop
  const SectionContainer = isMobile ? Fragment : ColumnContainer
  const sectionContainerProps = isMobile ? {} : { className }
  const Section = isMobile ? MobileSection : Column

  return (
    <>
      <BioContainer className={className}>
        <Bio $color={swatch.TEXT}>
          {parsedBio}
        </Bio>
      </BioContainer>
      <Sections $color={swatch.TEXT}>
        <SectionContainer {...sectionContainerProps}>
          <Section className={className} $end='span 6'>
            <h2>Services</h2>
            <PortableText value={services} components={studioList} />
          </Section>
          <Section className={className} $end='span 6'>
            <h2>Process</h2>
            {process && <Accordion items={process} />}
          </Section>
        </SectionContainer>
      </Sections>
    </>
  )
}

const BioContainerBase = styled.section`
  ${mixins.float()}
  opacity: 0;
`

const topPadding = SIZES.MARGIN.add(SIZES.PROJECT_TOP)
const BioContainerDesktop = styled(BioContainerBase)`
  min-height: ${SIZES.MAIN_H.sub(topPadding).css};
  padding-top: ${topPadding.css};
  display: grid;
  height: fit-content;
  align-items: end;
`

const BioContainerMobile = styled(BioContainerBase)`
  padding-top: ${SIZES.STUDIO_TOP_MOBILE.css};
`

const BioBase = styled.div`
  p {
    ${mixins.swatched()}
    color: ${extract('$color')};
    &:not(:first-child) {
      text-indent: 1.5em;
    }
  }
`

const BioDesktop = styled(BioBase)`
  p {
    line-height: ${FONT_SIZES.MEDIUM_LEADING.css};
    font-size: ${FONT_SIZES.MEDIUM.css};
  }
`

const BioMobile = styled(BioBase)`
  p {
    line-height: ${FONT_SIZES.MEDIUM_LEADING_MOBILE.css};
    font-size: ${FONT_SIZES.MEDIUM_MOBILE.css};
  }
`

const Sections = styled.section`
  > * {
    ${mixins.float()}
    opacity: 0;
    &, a, a:visited {
      ${mixins.swatched()}
      color: ${extract('$color')};
    }

    a {
      text-decoration-line: underline;
    }
  }
`

const ColumnContainer = styled(GridContainer)`
  padding-top: ${SIZES.STUDIO_BIO_BOT.css};
  padding-bottom: ${SIZES.MAIN_H.add(FONT_SIZES.SMALL_LEADING).css};

`

const Column = styled(GridItem)`
  font-size: ${FONT_SIZES.REGULAR.css};
`

const MobileSection = styled.div`
  h2 {
    padding-top: ${SIZES.STUDIO_HEADER_MARGIN_TOP_MOBILE.css};
    padding-bottom: ${SIZES.STUDIO_HEADER_PADDING_BOTTOM_MOBILE.css};
  }
`

export default Studio